import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import PageMeta from '@/components/app/pageMeta/PageMeta';
import Auth from '@/components/dashboard/auth/Auth';
import { COOKIE_KEY } from '@/constants/constants';
import { Loader } from '@proprioo/salatim';

const AuthDashboard = () => {
  const { t } = useTranslation();
  const { isReady, query } = useRouter();

  const cookies = new Cookies();
  const cookieToken: string = cookies.get(COOKIE_KEY);

  return (
    <PageMeta title={t('dashboard')} isNotIndexable={true}>
      {isReady ? (
        <Auth queryToken={query.token as string} cookieToken={cookieToken} />
      ) : (
        <Loader text={t('clientDashboardLoading')} />
      )}
    </PageMeta>
  );
};

export default AuthDashboard;
